import { ReactComponent as Logo } from "../images/BEAM-LOGO-PINK-TEXT.svg";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { useState, useEffect} from "react";
import React, { Dispatch, SetStateAction } from "react";

type SearchProps = {
  setQueryText: Dispatch<SetStateAction<string>>;
  queryText: string;
};

const UserMenu: React.FC<{
  givenName: string;
  familyName: string;
  email: string;
}> = (props: any) => {
  return (
    <div
      className="absolute right-10 top-12 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow-sm dark:bg-gray-700 dark:divide-gray-600"
      id="user-dropdown"
    >
      <div className="px-4 py-3">
        <span className="block text-sm text-gray-900 dark:text-white">
          {props.givenName} {props.familyName}
        </span>
        <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">
          {props.email}
        </span>
      </div>
      <ul className="py-2" aria-labelledby="user-menu-button">
        <li>
          <button
            onClick={() => {
              signOut();
            }}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          >
            Sign out
          </button>
        </li>
      </ul>
    </div>
  );
};

const Title: React.FC<SearchProps> = ({
  setQueryText,
  queryText,
}) => {
  type AmplifyAuthSession = Awaited<ReturnType<typeof fetchAuthSession>>;
  const [session, setSession] = useState<AmplifyAuthSession>();
  const [showUserMenu, setShowUserMenu] = useState(false);
  useEffect(() => {
    try {
      fetchAuthSession().then((session) => {
        if (session) {
          setSession(session);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);


  const given_name = session?.tokens?.idToken?.payload.given_name?.toString();
  const email = session?.tokens?.idToken?.payload.email?.toString();
  const family_name = session?.tokens?.idToken?.payload.family_name?.toString();
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900 justify-between shadow-sm dark:border-gray-700">
      <div className="w-screen flex flex-wrap items-center justify-between p-4">
        <div className="flex items-center space-x-3 rtl:space-x-reverse">
          <Logo className="w-32" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            Project Catalogue
          </span>
        </div>
        <div className="flex md:order-1">
          <div className="relative hidden md:block">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search icon</span>
            </div>
            <input
              type="text"
              id="search-navbar"
              className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search..."
              onChange={(e) => setQueryText(e.target.value)}
              value={queryText}
            />
          </div>
        </div>
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
  

          <button
            type="button"
            className="flex text-sm bg-beam-pink-6 rounded-full md:me-0 hover:bg-beam-pink-1"
            id="user-menu-button"
            aria-expanded="false"
            // data-dropdown-toggle="user-dropdown"
            onClick={() => setShowUserMenu(!showUserMenu)}
            
          >
            <span className="sr-only">Open user menu</span>

            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-beam-pink-6 rounded-full dark:bg-gray-600">
              <span className="font-bold text-base text-white dark:text-gray-300">
                {given_name?.charAt(0)}
                {family_name?.charAt(0)}
              </span>
            </div>
          </button>
          {showUserMenu ? (
            <UserMenu
              givenName={given_name || ""}
              familyName={family_name || ""}
              email={email || ""}
            />
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Title;
