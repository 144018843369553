import { Project } from "../models/Project";
import { Client } from "../models/Client";
import { Ipr } from "../models/IntellectualPropertyRight";
import { useState, useMemo } from "react";

import type {
  ColDef,
  ITextFilterParams,
} from "ag-grid-community";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
type TableProps = {
  projectList: Project[] | undefined;
  queryText: string;
}
const ProjectTable: React.FC<TableProps> = ({projectList, queryText,}) => {
  ModuleRegistry.registerModules([AllCommunityModule]);
  // Default Column Definition: Defines the default properties for all columns.  
  
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      flex: 1,
    };
  }, []);


  
  const IPFilterParams: ITextFilterParams = {
    filterOptions: ["contains", "notContains"],
    maxNumConditions: 1,
  };
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, ] = useState<ColDef<Project>[]>([
    { field: "code" },
    { field: "title" },
    { field: "type" },
    {
      field: "clients",
      valueFormatter: (params) =>
        params.value.map((el: Client) => el.name).join(", "),
    },
    { field: "vessel" },
    {
      field: "sensors",
      valueFormatter: (params) =>
        params.value.map((el: any) => el.name).join(", "),
    },
    { field: "size" },
    { field: "year" },
    {
      field: "intellectualPropertyRights",
      valueFormatter: (params) =>
        params.value.map((el: Ipr) => el.name).join(", "),
      headerName: "IP Rights",
      filter: true,
      filterParams: IPFilterParams,
    },
  ]);

  return (
    // Data Grid will fill the size of the parent container
    <div className="px-8 ag-theme-material" style={{ height: `calc(100vh - 72px)` }}>
      <AgGridReact
        quickFilterText={queryText}
        rowData={projectList}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export { ProjectTable };
