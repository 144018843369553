import { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { signInWithRedirect } from "aws-amplify/auth";
import {ReactComponent as Logo} from "../images/BEAM-LOGO-PINK-TEXT.svg";

const provider = {
  custom: "Okta",
};

export function Logout({ signOut, user }: WithAuthenticatorProps) {
  return (
    <div
      className="flex h-screen w-full items-center justify-center bg-rovco-blue bg-cover bg-no-repeat"
      style={{ color: "red" }}
    >
      <div className="rounded-xl bg-gray-800 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
        <div className="text-white">
          <div className="mb-8 flex flex-col items-center">
            <Logo className="w-64 h-40" />
            <h1 className="mb-2 text-2xl">Data Catalog</h1>
          </div>
          <div className="mt-8 flex justify-center text-lg text-black">
            <button
              onClick={() => signInWithRedirect({ provider })}
              type="submit"
              className="rounded-3xl bg-orange-vaarst px-10 py-2 text-white shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-rovco-coral"
            >
              Login with Okta
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
