import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Project } from "../models/Project";
import { fetchAuthSession } from "aws-amplify/auth";
import { CardRow } from "../components/CardRow";
import Map from "../components/Map";
import LocationCard from "../components/LocationCard";
import SensorsCard from "../components/SensorsCard";
import TapesCard from "../components/TapesCard";
import LinksCard from "../components/LinksCard";


export function ProjectDetail() {
  const [project, setProject] = useState<Project>();
  const ProjectDetailsParams = useParams<{ id: string }>();
  // const [_, setSession] = useState<AmplifyAuthSession | null>(null);
  useEffect(() => {
      fetchAuthSession().then((session) => {
        fetchData(session);
      });
    
  });
  const fetchData = (sess: any) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${sess.tokens.idToken}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow" as RequestRedirect,
    };

    console.log("fetching data,");
    fetch(
      process.env.REACT_APP_API_URL + "/api/v1/projects/" + ProjectDetailsParams.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setProject(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <main className="flex-col w-full">
      <div className="flex">
        <div className="p-8 m w-full">
          <div className="py-4">
            <h2 className="text-2xl text-orange-vaarst">
              Project: {project?.title} ({project?.code})
            </h2>
          </div>
          <CardRow project={project} />
        </div>
      </div>
      
      <div className="flex flex-row m-w-full px-8">
        <div className="flex flex-col mr-3">
          <LocationCard location={project?.location || ""} latitude={project?.latitude || 0} longitude={project?.longitude || 0}/>
          <SensorsCard sensors={project?.sensors || []} />
          <TapesCard tapes={project?.tapes || []} />
          <LinksCard contract={project?.contractLink || ""} />
        </div>
        <Map
          longitude={project?.longitude || 0}
          latitude={project?.latitude || 0}
        />
        <div className="flex flex-col mr-3">

        </div>
      </div>
    </main>
  );
}
