import { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { signInWithRedirect } from "aws-amplify/auth";
import bg from "../images/AdobeStock_799366309.jpeg";

import { ReactComponent as Logo } from "../images/BEAM-LOGO-PINK-TEXT.svg";

const provider = {
  custom: "Okta",
};

export function Login({ signOut, user }: WithAuthenticatorProps) {
  return (
    <div
      className="flex h-screen w-full items-center justify-center bg-cover bg-no-repeat"

      style={{ backgroundImage: `url(${bg})` }}
    >

      <div className="rounded-xl bg-zinc-100 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
        <div className="text-rovco-blue">
          <div className="mb-8 flex flex-col items-center">
            <Logo className="w-64 h-40" />
            <h1 className="mb-2 text-4xl">Data Catalog</h1>
          </div>
          <div className="mt-8 flex justify-center text-lg text-black">
            <button
              onClick={() => signInWithRedirect({ provider })}
              type="submit"
              className="rounded-3xl bg-beam-pink-default px-10 py-2 text-white shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-beam-pink-8"
            >
              Login with Okta
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
