import { useState, useEffect } from "react";

import { ProjectTable } from "../components/ProjectTable";
import { Project } from "../models/Project";
import { Client } from "../models/Client";
import { fetchAuthSession } from "aws-amplify/auth";
import NewProjectModal from "./NewProjectModal";
import { Sensor } from "../models/Sensor";
import { Ipr } from "../models/IntellectualPropertyRight";
import { Nas } from "../models/Nas";
import { Tape } from "../models/Tape";
import Title from "../components/Title";

export function Projects() {
  const [queryText, setQueryText] = useState<string>("");
  const [projectList, setProjectList] = useState<Project[]>();
  const [clientList, setClientList] = useState<Client[]>();
  const [sensorList, setSensorList] = useState<Sensor[]>();
  const [ipRightsList, setIpRightsList] = useState<Ipr[]>();
  const [nasList, setNasList] = useState<Nas[]>();
  const [tapeList, setTapeList] = useState<Tape[]>();
  type AmplifyAuthSession = Awaited<ReturnType<typeof fetchAuthSession>>;
  const [session, setSession] = useState<AmplifyAuthSession | null>(null);
  const [showModal, setShowModal] = useState(false);
  
  const fetchData = (sess: any, endpoint: string, stateFunction: any) => {
    var headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${sess.tokens.idToken}`);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow" as RequestRedirect,
    };
    fetch(process.env.REACT_APP_API_URL + "/api/v1/" + endpoint, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        stateFunction(result);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    try {
      fetchAuthSession().then((session) => {
        if (session) {
          setSession(session);
        }
        fetchData(session, "projects", setProjectList);
        fetchData(session, "clients", setClientList);
        fetchData(session, "sensors", setSensorList);
        fetchData(session, "ip-rights", setIpRightsList);
        fetchData(session, "nas", setNasList);
        fetchData(session, "tapes", setTapeList);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);
  return (
    <main className="flex-col w-full h-full">
      <Title
        setQueryText={setQueryText}
        queryText={queryText}
      />
      <div className="flex-grow">
        <ProjectTable
          projectList={projectList}
          queryText={queryText}
        />
      </div>
      <div className="fixed right-0 bottom-0 p-2 z-50">
        {session?.tokens?.idToken?.payload["cognito:groups"]
          ?.toString()
          .includes("data-catalog-admin") === true ? (
          <button
            onClick={() => setShowModal(true)}
            type="button"
            className="text-white bg-beam-pink-default hover:bg-beam-pink focus:outline-2 focus:outline-offset-2 focus:outline-violet-500 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              className="w-6 h-6 text-white dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12h14m-7 7V5"
              />
            </svg>

            <span className="sr-only">Icon description</span>
          </button>
        ) : null}
      </div>

      {showModal ? (
        <NewProjectModal
          setShowModal={setShowModal}
          clientList={clientList}
          setClientList={setClientList}
          sensorList={sensorList}
          setSensorList={setSensorList}
          ipRightsList={ipRightsList}
          setIpRightsList={setIpRightsList}
          nasList={nasList}
          setNasList={setNasList}
          tapeList={tapeList}
          session={session}
          setTapeList={setTapeList}
          setProjectList={setProjectList}
          projectList={projectList}
        />
      ) : null}
    </main>
  );
}
