import CreatableSelect from "react-select/creatable";
import CreateItem from "../utils/Create";
import { useState } from "react";
interface Option {
  readonly label: string | undefined;
  readonly value: number;
}
const NewProjectSelect = ({
  setSelectedList,
  selectedList,
  setList,
  List,
  type
}: {
  // eslint-disable-next-line no-empty-pattern
  setSelectedList: ([]) => void;
  selectedList: {
    ID: number;
    name?: string | undefined;
  }[];
  // eslint-disable-next-line no-empty-pattern
  setList: ([]) => void;
  List: { ID: number; name?: string | undefined }[];
  type: string;
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  return (
    <CreatableSelect
      name={type}
      id={type}
      isMulti
      onChange={(e) => {
        setSelectedList(e.map((element) => ({ ID: element.value })));
        setOptions(
          e.map((element) => ({ value: element.value, label: element.label }))
        );
      }}
      onCreateOption={(e) => {
        CreateItem(type, { name: e }).then((item) => {
          setSelectedList([...(selectedList || []), item]);
          setOptions([...options, { value: options.length, label: e }]);
          setList([...List, item]);
        });
      }}
      value={options}
      classNames={{
        control: () =>
          " border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-0.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
      }}
      options={List?.map((client) => ({
        value: client.ID,
        label: client.name,
      }))}
    />
  );
};
export default NewProjectSelect;
